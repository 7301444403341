<template>
  <div class="invoices-report">
    <v-card class="mb-2">
      <v-card-title> Laporan Tagihan Penjualan </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Total Tagihan </v-card-title>

      <v-card-text>
        <h2 v-text="totalAfterReturns"></h2>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr class="header">
                <td>Retur</td>
                <td v-text="returnAllocations" class="text-right"></td>
              </tr>
              <tr>
                <td>Tagihan Awal</td>
                <td v-text="total" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="fetchData"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="invoices"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="light-blue"
            small
            @click="openDetails(item.branch_code)"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  components: { DateRangePicker },

  data() {
    return {
      count: 0,

      dateRange: null,

      search: '',

      options: {},

      headers: [
        { text: 'Customer', value: 'br_name' },
        { text: 'Total', value: 'total_after_returns' },
        { text: 'Retur', value: 'return_alloc' },
        { text: 'Tagihan Awal', value: 'total' },
        { text: '', sortable: false, value: 'actions' },
      ],

      loading: false,

      invoices: [],

      returnAllocations: 0,

      total: 0,

      totalAfterReturns: 0,
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.fetchData()
      },
    },

    dateRange() {
      this.fetchData()
    },
  },

  methods: {
    fetchData() {
      if (!this.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.invoices = []

      this.loading = true

      this.$http
        .get('/sales/reports/invoices', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then(({ data }) => {
          this.count = data.count

          this.invoices = data.result

          this.loading = false

          this.returnAllocations = data.return_allocations

          this.total = data.total

          this.totalAfterReturns = data.total_after_returns
        })
    },

    openDetails(branchCode) {
      this.$router.push(
        '/sales/invoices/' +
          branchCode +
          '/' +
          this.date2sql(this.dateRange.start) +
          '/' +
          this.date2sql(this.dateRange.end)
      )
    },
  },
}
</script>

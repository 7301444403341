<template>
  <div class="invoices"><invoices-report /></div>
</template>

<script>
import InvoicesReport from "@/components/Reports/Sales/InvoicesReport.vue";

export default {
  name: "Invoices",
  components: { InvoicesReport },
};
</script>